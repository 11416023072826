<template>
  <v-dialog v-model="mostrar" persistent width="450">
    <v-card :loading="loading">
      <v-toolbar dense flat color="primary" dark>
        <v-toolbar-title>Seleccionar área</v-toolbar-title>
      </v-toolbar>
      <v-card-text class="pt-3 pb-0 px-4">
        <span
          >Selecciona el área donde donde quedará guardada la copia de la
          encuesta:</span
        >
        <v-row dense class="mt-2">
          <v-col cols="12">
            <v-select
              v-model="areaSeleccionada"
              :items="areas"
              item-value="_id"
              item-text="nombre"
              label="Área"
              outlined
              dense
              :disabled="loading"
              no-data-text="No hay áreas disponibles"
            >
            </v-select>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          small
          color="secondary"
          outlined
          :disabled="loading"
          @click="$emit('cancelar')"
          >Cancelar</v-btn
        >
        <v-btn
          small
          color="primary"
          :disabled="loading"
          :loading="loading"
          @click="submit()"
          >Guardar</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { clonarEncuesta } from "./encuestas.service";

export default {
  props: {
    mostrar: { type: Boolean, default: false },
    areas: { type: Array, default: () => [] },
    encuesta: { type: Object, default: () => null },
  },

  data: () => ({
    loading: false,
    areaSeleccionada: "",
  }),

  watch: {
    areas() {
      this.setAreaSeleccionada();
    },
  },

  mounted() {
    this.setAreaSeleccionada();
  },

  methods: {
    setAreaSeleccionada() {
      if (this.encuesta && this.areas.length && this.encuesta.areaEncuesta) {
        this.areaSeleccionada = this.areas.filter(
          (e) => e._id === this.encuesta.areaEncuesta
        )[0]._id;
      } else if (this.encuesta && this.areas.length)
        this.areaSeleccionada = this.areas[0]._id;
    },

    async submit() {
      this.loading = true;

      try {
        const data = {
          areaEncuesta: this.areaSeleccionada,
        };

        const serverResponse = await clonarEncuesta(this.encuesta._id, data);
        this.loading = false;

        this.$validateResponse(serverResponse);
        if (!serverResponse.ok)
          this.$systemErrorMessage(serverResponse.mensaje);
        else this.$emit("encuestaClonada", serverResponse.encuesta);
      } catch (error) {
        this.loading = false;
        this.$appErrorMessage();
      }
    },
  },
};
</script>
